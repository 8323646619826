import 'bootstrap/dist/js/bootstrap.bundle.min';
import './js/fancybox.js';
import './js/pretty-drowpdowns.js';
import Swiper from 'swiper/bundle';

jQuery(function($) {
    
    // Pretty dropdowns
    $('select').prettyDropdown({
        height: 50
    });

    // fill job listing modal hidden field with a title
    $('.modal-button').click(function() {
        var jobListingTitle = $(this).closest('.job-listing').find('.job-listing-title').text();
        console.log(jobListingTitle);
        $('input[name="job_listing_title"]').val(jobListingTitle);
    });

    // Wrap table to div
    $('table').wrap('<div class="table-responsive"></div>');

    // Mobile menu
    $('.navbar-toggler-mob').on('click', function () {
        $('#navbar-collapse-mob').toggleClass('show');
        $('#navbar-collapse-mob').css({'z-index': '100', 'opacity': '1' });
    });

    // For mobile menu first click open menu, second page
    if (jQuery( window ).width() < 1239) {
        $('.menu-item-has-children').on("click", function(e) {
            if ($('.dropdown-menu').css('display') == 'block') {
                $('.dropdown-menu').css('display', 'none');
            } else {
                e.preventDefault();
                $('.dropdown-menu').css('display', 'block');
            }
        });
    }

    if (jQuery( window ).width() > 1239) {
        $(window).scroll(function() {    
            var scroll = $(window).scrollTop();
        
            if (scroll >= 1) {
                $("header").addClass("active");
            } else {
                $("header").removeClass("active");
            }
        });
    }

    // Lang switcher
    $( ".language-selector" ).hover(function() {
        $( ".lang-dropdown" ).toggle();
        $( ".lang-toggle" ).toggleClass('opened');
    });

    
    // Lang switcher
    $( ".under-mob-menu .language-selector" ).click(function() {
        $( ".lang-dropdown" ).toggle();
        $( ".lang-toggle" ).toggleClass('opened');
    });

    $(document).mouseup(function(e){
        var langContainer = $(".lang-dropdown");
        if(!langContainer.is(e.target) && langContainer.has(e.target).length === 0){
            langContainer.hide();
        }
    });

    $(".dropdown-menu").each(function(index) {
        var elem = $(this);
        var menu = elem.closest(".menu-row"); // Find the closest .menu-row ancestor
    
        var offset = menu.offset().left - elem.parent().offset().left;
        elem.css('left', offset);
    });

    new Swiper(".logosSwiper", {
        slidesPerView: 3,
        spaceBetween: 25,
        loop: true,
        autoplay: true,
        breakpoints: {
            600: {
                slidesPerView: 5,
            },
            1200: {
                slidesPerView: 7,
            }
        },
    });

    new Swiper(".newsSwiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    new Swiper(".benefitsSwiper", {
        slidesPerView: 1,
        spaceBetween: 32,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            600: {
                slidesPerView: 2,
            },
        },
    });

    new Swiper(".personalStoriesSwiper", {
        slidesPerView: 1,
        spaceBetween: 30,
        pagination: {
            el: ".swiper-pagination",
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            700: {
                slidesPerView: 2,
            },
        },
    });

    $('.swiper-container').each(function(index) {
        var sliderId = $(this).data('slider-id');
        var swiper = new Swiper('.archiveProducsSwiper-' + sliderId, {
            slidesPerView: 1,
            spaceBetween: 30,
            watchOverflow: true,
            pagination: {
                el: ".swiper-pagination-" + sliderId,
            },
            navigation: {
                nextEl: ".swiper-mob-button-next-" + sliderId,
                prevEl: ".swiper-mob-button-prev-" + sliderId,
            },
            breakpoints: {
                600: {
                    slidesPerView: 2,
                },
                768: {
                    navigation: {
                        nextEl: ".swiper-button-next-" + sliderId,
                        prevEl: ".swiper-button-prev-" + sliderId,
                    },
                },
                1200: {
                    slidesPerView: 3,
                }
            },
        });

        // Add event listeners to custom navigation
        $('.swiper-button-prev-' + sliderId).on('click', function() {
            swiper.slidePrev();
        });

        $('.swiper-button-next-' + sliderId).on('click', function() {
            swiper.slideNext();
        });

        $('.swiper-mob-button-prev-' + sliderId).on('click', function() {
            swiper.slidePrev();
        });

        $('.swiper-mob-button-next-' + sliderId).on('click', function() {
            swiper.slideNext();
        });
    });

    new Swiper(".productLogoSwiper", {
        slidesPerView: 3,
        spaceBetween: 22,
        loop: true,
        autoplay: true,
        breakpoints: {
            600: {
                slidesPerView: 4,
            },
        },
    });

    var productThumbSwiper = new Swiper(".productThumbSwiper", {
        loop: true,
        spaceBetween: 8,
        slidesPerView: 5,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    
    var prodSwiper = new Swiper(".productSwiper", {
        loop: true,
        spaceBetween: 5,
        thumbs: {
            swiper: productThumbSwiper,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });
    
    jQuery(this).find('.swiper-button-prev-mob').on('click', function(e){
        e.preventDefault();
        prodSwiper.slideTo(prodSwiper.activeIndex-1);
        productThumbSwiper.slideTo(productThumbSwiper.activeIndex-1);
      });
    jQuery(this).find('.swiper-button-next-mob').on('click', function(e){
        e.preventDefault();
        prodSwiper.slideTo(prodSwiper.activeIndex+1);
        productThumbSwiper.slideTo(productThumbSwiper.activeIndex+1);
    });

    $(document).ready(function(){
        $('.read-more').click(function(){
            var $block = $(this).closest('.expandable-text-block');
            
            $block.find('.read-more-text, .read-less-text').toggleClass('disp-none');
            $block.find('.show-read-more, .show-read-less').toggleClass('disp-none');
        });
    });

    function setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
    }

    $('.cb-promo-bar a.close-bar').click(function () {
        setCookie('cb-promo-bar', 'hide', 30);
        $('.cb-promo-bar').fadeOut('fast');
    });

});