import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css"; 

Fancybox.bind('[data-fancybox="gallery"]', {
    Thumbs: {
        type: 'classic',
    },
    Toolbar: {
        display: {
          left: [],
          middle: [],
          right: ["close"],
        },
    },
});

Fancybox.bind('[data-fancybox]', {});